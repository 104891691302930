import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import icon1 from "assets/images/CountryStories/uk-pg3-icon1.png";
import icon2 from "assets/images/CountryStories/uk-pg3-icon2.png";
import icon3 from "assets/images/CountryStories/uk-pg3-icon3.png";

import "./CountryPages.scss";

const UkrainePage3 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Ukraine Page 3"
      id="uk3"
      name="uk3"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        IS MSSD Functions and Impact
      </header>
      <section className="story-content">
        <div className="main-row">
          <div className="wrapper">
            <span className="icon">
              <img src={icon1} alt="" aria-hidden={true} />
            </span>
            <span className="list" tabIndex={0} role="note" name="note1">
              <h4>Reporting and Use</h4>
              <ul>
                <li>Supports MER, national, and international reporting</li>
                <li>
                  3,247 healthcare workers registered in 592 facilities in 24
                  regions
                </li>
                <li>
                  664,971 electronic medical records, including 116,857 ART
                  patients, 330,483 TB patients, and 24,854 MAT patients
                </li>
              </ul>
            </span>
          </div>
          <div className="wrapper">
            <span className="icon">
              <img src={icon2} alt="" aria-hidden={true} />
            </span>
            <span className="list" tabIndex={0} role="note" name="note2">
              <h4>Clinical data & Strategic information</h4>
              <ul>
                <li>Access to clinical data for healthcare professionals</li>
                <li>Tracking services using registers and logbooks</li>
                <li>
                  Access to non-personified information for data analysis and
                  strategic decision making
                </li>
                <li>DQA</li>
                <li>
                  Accounting and logistics for medications and disposables
                </li>
                <li>Patient access to clinical data, visits, and scheduling</li>
              </ul>
            </span>
          </div>
          <div className="wrapper">
            <span className="icon">
              <img src={icon3} alt="" aria-hidden={true} />
            </span>
            <span className="list" tabIndex={0} role="note" name="note3">
              <h4>Interoperability</h4>
              <ul>
                <li>
                  Data exchange with national eHealth systems
                  <ul>
                    <li>Electronic patient records</li>
                    <li>Diagnostic reports</li>
                    <li>E-prescriptions</li>
                    <li>Billing integration</li>
                  </ul>
                </li>
                <li>
                  Data exchange with LIMS
                  <ul>
                    <li>E-prescription for lab tests</li>
                    <li>Registration and tracking of specimens</li>
                    <li>Transfer of test results to IS MSSD</li>
                  </ul>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UkrainePage3;
