import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import timeline from "assets/images/CountryStories/zambiaTimeline.png";
import "./CountryPages.scss";

const ZambiaPag3 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Zambia Page 3"
      id="zamP3"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        SmartCare Timeline
      </header>
      <section className="story-content">
        <div className="img-container">
          <div className="image-frame">
            <img
              src={timeline}
              tabIndex={0}
              name="img1"
              role="img"
              alt="Timeline of development of Smartcare from 2005 to current day within Zambia."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ZambiaPag3;
