import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import pageIcon from "assets/images/CountryStories/uk-pageIcon.png";
import books from "assets/images/CountryStories/ukImg1.png";
import arrow from "assets/images/CountryStories/ukImg2.png";

import "./CountryPages.scss";

const UkrainePage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Ukraine Page 1"
      id="uk1"
      name="uk1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        HIV Data in Ukraine: A PEPFAR-Supported Revolution
      </header>
      <section className="story-content">
        <div className="main-row">
          <div className="col1" tabIndex={0} name="col1" role="note">
            <div className="icon-row-1">
              <div className="image-frame">
                <img src={pageIcon} alt="" aria-hidden={true} />
              </div>
              <h5>DATA RECORDS SYSTEMS AND REPORTING BEFORE PEPFAR SUPPORT</h5>
            </div>
            <div>
              <ul id="bullets">
                <li>
                  <u>Primary:</u> Paper-based patients records
                </li>
                <li>
                  <u>Secondary:</u>Limited, unintegrated electronic
                  tables/databases (mostly Excel) for HR, accounting,
                  information systems, and logistics
                </li>
                <li>
                  <u>Reporting:</u> Aggregation of paper-based data at
                  regional/national levels ​
                </li>
              </ul>
            </div>
          </div>
          <div className="col2">
            <div className="row1" tabIndex={0} name="col2" role="note">
              <div className="image-frame">
                <img
                  src={books}
                  id="books"
                  alt="HIV data records and reporting before PEPFAR support  was paper based with many challenges."
                  aria-hidden={true}
                />
              </div>
              <div className="challenges-container">
                <div>
                  <h3>
                    <u>KEY CHALLENGES AND GAPS:</u>
                  </h3>
                </div>
                <div className="challenge">
                  <ul>
                    <li>
                      Absence of unified approach for data collection, security,
                      privacy, confidentiality, storage and analyses, resulting
                      in an incomplete registry of HIV patients and inaccurate
                      reporting
                    </li>
                    <li>
                      Time-consuming process to obtain information at national
                      and regional levels with no real-time data on ARVs and
                      other medical supplies
                    </li>
                    <li>
                      Excessive/inefficient workload for healthcare workers, who
                      often lacked IT equipment and digital skills
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="image-frame">
              <img
                src={arrow}
                id="arrow"
                alt="2012: PEPFAR launches AccessPro, a partnership with civil society and the Government of Ukraine, to create a world-class information system for HIV and other socially significant diseases."
                tabIndex={0}
                name="arrow"
                role="img"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UkrainePage1;
