import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { chartColors } from "components/CountryStories/charts";
import SMXChart from "components/Widgits/SMXChart";
import img1 from "assets/images/CountryStories/DR-2-img1.png";
import img2 from "assets/images/CountryStories/DR-2-img2.png";
import { getChartFontSize, getChartTitleSize } from "common";
import "./CountryPages.scss";

const DominicanRepublic2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);
  const currentFontSize = getChartFontSize();

  const labelOption = {
    show: true,
    position: "inside",
    distance: 15,
    align: "center",
    verticalAlign: "middle",
    fontSize: currentFontSize,
    fontWeight: "bold",
    formatter: function (value) {
      return value.data > 0 ? value.data : "";
    },
  };

  const chartOptions = {
    aria: {
      show: false,
    },
    grid: { show: false, bottom: "10%" },
    title: {
      // text: "",
      subtext: "September 2023",
      left: "center",
      // textStyle: {
      //   color: theme.color,
      //   fontSize: getChartTitleSize(),
      // },
      subtextStyle: {
        color: theme.color,
        fontSize: getChartTitleSize(),
        fontStyle: "bold",
      },
    },
    legend: {
      orient: "horizontal",
      bottom: 0,
      textStyle: {
        color: theme.color,
        fontSize: currentFontSize,
        fontWeight: "bold",
      },
    },
    tooltip: {},
    color: chartColors,
    xAxis: [
      {
        type: "category",
        axisTick: { show: true },
        axisLabel: {
          color: theme.color,
          fontWeight: "bold",
          fontSize: currentFontSize,
        },
        data: ["2021 Estimation", "First 95", "Second 95", "Third 95"],
      },
    ],
    yAxis: [
      {
        type: "value",
        position: "left",
        min: 0,
        max: 80000,
        axisLabel: {
          color: theme.color,
          fontWeight: "bold",
          fontSize: currentFontSize,
        },
      },
      {
        type: "value",
        position: "right",
        min: 0,
        max: 110,
        axisLabel: {
          formatter: "{value}%",
          color: theme.color,
          fontSize: currentFontSize,
          fontWeight: "bold",
        },
      },
    ],
    series: [
      {
        type: "bar",
        barGap: 0,
        label: labelOption,
        emphasis: {
          focus: "series",
        },
        data: [78000, 74100, 70395, 68875],
      },
      {
        type: "bar",
        label: labelOption,
        emphasis: {
          focus: "series",
        },
        data: [0, 74585, 52847, 44406],
      },
      {
        type: "line",
        yAxisIndex: 1,
        symbolSize: 12,
        lineStyle: { width: 5.0 },
        data: [null, 95, 90.25, 85.74],
        emphasis: {
          focus: "series",
        },
        tooltip: {
          valueFormatter: function (value) {
            return value + "%";
          },
        },
      },
      {
        type: "line",
        yAxisIndex: 1,
        symbolSize: 12,
        lineStyle: { width: 5.0 },
        emphasis: {
          focus: "series",
        },
        data: [null, 100.65, 75.07, 66.4],
        tooltip: {
          valueFormatter: function (value) {
            return value + "%";
          },
        },
      },
    ],
  };

  return (
    <div
      role="dialog"
      aria-label="DR Page 2"
      id="drP2"
      name="drP2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        Impact after 11 years of PEPFAR 2023
      </header>
      <section className="story-content">
        <div className="page-grid">
          <div className="top-container">
            <div className="top-left-div" name="img1" role="note" tabIndex={0}>
              <h4>One National System (FAPPS*)</h4>
              <div className="imahe-frame">
                <img id="img1" src={img1} alt="alt text" />
              </div>
            </div>
            <div
              tabIndex={0}
              name="chart"
              role="note"
              className="top-right-div"
              aria-label="Flow chart and dashboard from FAPPS. FAPPS serves as the main national HIV treatment database for the Dominican Republic."
            >
              <div>
                <h4>FAPPS Dashboard</h4>
              </div>
              <SMXChart options={chartOptions} chartCallback={null} />
              <div>
                <h6>
                  Example of FAPPS dashboard. Today, FAPPS serves as the main
                  national HIV treatment database. It allows patient tracking
                  and provides quality data to both the national response and
                  international cooperants.
                </h6>
              </div>
            </div>
          </div>
          <div
            className="bottom-container"
            tabIndex={0}
            name="img3"
            role="note"
          >
            <h6 style={{ textAlign: "left" }}>
              In progress: HIS Interoperability Landscape Analysis.
            </h6>
            <div className="image-frame">
              <img id="img2" src={img2} alt="alt text" />
            </div>
            <h6 style={{ textAlign: "left" }}>
              * FAPPS Formulario de Aplicación de Políticas Públicas Sociales.
            </h6>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DominicanRepublic2;
