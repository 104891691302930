import React from "react";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { chartColors } from "../charts";
import { getChartFontSize, getChartTitleSize } from "common";
import "components/CountryStories/pages/CountryPages.scss";

const HaitiPageTwo = () => {
  const theme = useSelector(selectCompliance).theme;
  const currentFontSize = getChartFontSize();

  const chartOptions1 = {
    aria: {
      show: false,
      enabled: true,
    },
    title: {
      text: "PLHIV Living in Areas in Critical Security Situation",
      left: "center",
      subtext:
        "Active Patients and Interruption in Treatment for less than 6 months in CRITICAL SECURITY SITUATIONS",
      textStyle: {
        fontSize: getChartTitleSize(),
        fontWeight: "bold",
      },
      subtextStyle: {
        color: theme.color,
        fontSize: currentFontSize,
      },
    },
    grid: { top: "15%", bottom: "8%" },
    legend: {
      orient: "horizontal",
      bottom: 10,
      textStyle: {
        color: theme.color,
        fontSize: currentFontSize,
        fontWeight: "bold",
      },
    },
    color: chartColors,
    tooltip: {
      formatter: "{b}\n{d}%",
    },
    xAxis: {},
    yAxis: {},
    data: [
      "Croix des Bouquets",
      "Carrefour Feuilles",
      "Martissant",
      "Bon Repos",
      "Solino",
      "Cx des Missions",
    ],
    series: [
      {
        type: "pie",
        label: {
          show: true,
          // position: "inside",
          fontSize: currentFontSize,
          fontWeight: "bold",
          formatter: function (params) {
            return params.value
              .toLocaleString()
              .concat("\n")
              .concat(params.percent)
              .concat("%");
          },
        },
        data: [
          { value: 3854, name: "Croix des Bouquets" },
          { value: 2038, name: "Carrefour Feuilles" },
          { value: 1637, name: "Martissant" },
          { value: 1517, name: "Bon Repos" },
          { value: 813, name: "Solino" },
          { value: 558, name: "Cx des Missions" },
        ],
      },
    ],
  };

  return (
    <div
      id="haitiPg2"
      aria-label="Haiti Page 2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor }}
    >
      <header className="story-title" tabIndex={0} name="title" role="note">
        Person-Centered Monitoring in Haiti​
      </header>
      <section>
        <div className="main-row">
          <div
            className="chart-div"
            name="section1"
            role="note"
            tabIndex={0}
            style={{ background: theme.bgGradient }}
            aria-label="Pie chart of PLHIV living in areas in critical security situation. "
          >
            <ReactECharts
              option={chartOptions1}
              style={{ height: "95%" }}
              onChartReady={null}
              opts={{ renderer: "canvas" }}
            />
          </div>
          <div className="text-block" name="section2" role="note" tabIndex={0}>
            <p>
              This figure shows data extracted from PLR (Patient Linkage and
              Retention) system highlighting the magnitude and the distribution
              of PLHIV living in critical security areas.
            </p>
            <p>
              This report allows the National AIDS Program to reach, with the
              support of the Implementing partners and stakeholders, all
              displaced PLHIV to provide them the needed support or refill them
              with their medications if necessary.
            </p>
            <ul>
              <li>
                10,417 PLHIV (21% of Ouest department [SNU]) are currently
                living in areas affected by ongoing Gang Violence in the
                metropolitan area​
              </li>
              <li>
                The area of{" "}
                <font
                  style={{
                    color: chartColors[1],
                    fontSize: currentFontSize,
                    fontWeight: 800,
                  }}
                >
                  Carrefour-Feuilles
                </font>
                , with 2,038 PLHIV, is second to the{" "}
                <font
                  style={{
                    color: chartColors[0],
                    fontSize: currentFontSize,
                    fontWeight: 800,
                  }}
                >
                  Croix des Bouquets
                </font>{" "}
                area.​
              </li>
              <li>
                <font
                  style={{
                    color: chartColors[2],
                    fontSize: currentFontSize,
                    fontWeight: 800,
                  }}
                >
                  Martissant
                </font>{" "}
                and{" "}
                <font
                  style={{
                    color: chartColors[3],
                    fontSize: currentFontSize,
                    fontWeight: 800,
                  }}
                >
                  Bon Repos
                </font>{" "}
                areas have been facing relentless gang violence for more than 2
                years.​
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HaitiPageTwo;
