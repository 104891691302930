import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import timeline from "assets/images/CountryStories/Vietnam-Timeline.png";
import chart from "assets/images/CountryStories/Vietnam-Pg1-chart.png";
import file from "assets/images/CountryStories/vn-fileIcon.png";
import globe from "assets/images/CountryStories/vn-globeIcon.png";
import books from "assets/images/CountryStories/vn-booksIcon.png";

import "./CountryPages.scss";

const VietNamPage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="note"
      aria-label=""
      id="namP1"
      name="namP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        U.S. Government Supports Vietnam HIV Response through PEPFAR since 2004
      </header>
      <section className="story-content">
        <div className="main-stack">
          <div className="timeline">
            <div className="image-frame">
              <img
                src={timeline}
                id="vnimg1"
                alt="Timeline of HIV epidemic and PEPFAR support within Vietnam with a graph below displaying the national and PEPFAR contributions to results."
                tabIndex={0}
                name="timeline"
                role="img"
              />
            </div>
          </div>
          <div className="row2">
            <div tabIndex={0} role="note" name="note1">
              <h4>The first HIV case was diagnosed in Vietnam</h4>
            </div>
            <div tabIndex={0} role="note" name="note2">
              <h4>Challenges at the Beginning</h4>
              <div className="list-item">
                <img src={globe} className="icon" aria-hidden={true} />
                <p>
                  National HIV case reporting system (HIV INFO) established, but
                  decentralized and offline.
                </p>
              </div>
              <div className="list-item">
                <img src={file} className="icon" aria-hidden={true} />
                <p>
                  No electronic medical record systems for HIV and related
                  burdens.
                </p>
              </div>
              <div className="list-item">
                <img src={books} className="icon" aria-hidden={true} />
                <p>
                  National HIV program monitoring data system still in early
                  stages, burden of data aggregation from paper-based documents
                  and records.
                </p>
              </div>
            </div>
            <div id="chart-img" className="image-frame">
              <img
                src={chart}
                id="vnimg2"
                alt="vietnam chart"
                tabIndex={0}
                name="chart"
                role="img"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VietNamPage1;
