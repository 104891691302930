import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import img1 from "assets/images/CountryStories/togoPg2.png";
import "./CountryPages.scss";

const TogoPage2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="TogoPage2"
      id="tgPg2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        PEPFAR Used Evidence and Advocacy to Convince the National Program of
        the eTracker Efficacy​
      </header>
      <section className="story-content">
        <div className="img-container">
          <div className="image-frame">
            <img
              src={img1}
              tabIndex={0}
              name="img1"
              role="img"
              alt="Successful ETracker facility scale up with timeline. Ministry of health with the global fund and PEPFAR has scaled up the national etracker to 79 health facilities."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default TogoPage2;
