import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import centerIcon from "assets/images/CountryStories/DR-Center-Icon.png";
import tlIcon from "assets/images/CountryStories/DR-TL-Icon.png";
import trIcon from "assets/images/CountryStories/DR-TR-Icon.png";
import blIcon from "assets/images/CountryStories/DR-BL-Icon.png";
import brIcon from "assets/images/CountryStories/DR-BR-Icon.png";
import ribbon from "assets/images/CountryStories/DR-Ribbon.png";

import "./CountryPages.scss";

const DominicanRepublic1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="DR Page 1"
      id="drP1"
      name="drP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        The Dominican Republic (DR) HIV Health Information System (HIS) ​ -2012-
      </header>
      <section className="story-content">
        <div className="outer-stack">
          <div className="main-row">
            <div className="col1">
              <div className="img-right" tabIndex={0} name="img1" role="note">
                <div>
                  <h4>Limited data for HIV estimations</h4>
                  <p>
                    Lack of centralized database for tracking HIV cases. No
                    scientific way to understand the HIV epidemic.
                  </p>
                </div>
                <div className="image-frame">
                  <img src={tlIcon} aria-hidden={true} />
                </div>
              </div>
              <div className="img-right" tabIndex={0} name="img2" role="note">
                <div>
                  <h4>Increased workload for service providers</h4>
                  <p>
                    Lack of systematic approach created additional workload for
                    staff​.
                  </p>
                </div>
                <div className="image-frame">
                  <img src={blIcon} aria-hidden={true} />
                </div>
              </div>
            </div>

            <div className="col2">
              <div className="image-frame">
                <img
                  name="img0"
                  role="img"
                  tabIndex={0}
                  src={centerIcon}
                  alt="No vision for a National System"
                />
              </div>
            </div>
            <div className="col3">
              <div className="img-left" tabIndex={0} name="img3" role="note">
                <div className="image-frame">
                  <img src={trIcon} aria-hidden={true} />
                </div>
                <div>
                  <h4>Non standardized forms​</h4>
                  <p>
                    Each clinical site was using its own data collection tool​.
                  </p>
                </div>
              </div>
              <div className="img-left" tabIndex={0} name="img4" role="note">
                <div className="image-frame">
                  <img src={brIcon} aria-hidden={true} />
                </div>
                <div>
                  <h4>Poor data for decision making​</h4>
                  <p>
                    HIV testing results and viral load data not accessible.
                    Ineffective ART resource allocation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="image-frame">
              <img
                className="ribbon"
                src={ribbon}
                tabIndex={0}
                name="img5"
                role="img"
                alt="Pepfar and DR HIV national response worked closely to create a new HIS that could solve these challenges."
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DominicanRepublic1;
