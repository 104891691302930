import Raven from "raven-js";
import logger from "services/LoggingService";

// Raven.config('https://examplePublicKey@o0.ingest.sentry.io/0').install();

export const reduxLogger = (store) => (next) => (action) => {
  const result = next(action);
  logger.debug(`REDUX dispatching: ${action.type}`, store.getState());
  return result;
};

export const crashReporter = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    logger.error("Caught an exception!", err);
    Raven.captureException(err, {
      extra: {
        action,
        state: store.getState(),
      },
    });
    throw err;
  }
};
