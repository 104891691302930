import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import img2012 from "assets/images/CountryStories/malawi-2012.png";
import img2018 from "assets/images/CountryStories/malawi-2018.png";
import img2019 from "assets/images/CountryStories/malawi-2019.png";
import img2023 from "assets/images/CountryStories/malawi-2023.png";
import "components/CountryStories/pages/CountryPages.scss";

const MalawiPage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  useEffect(() => {
    // firstField.current.focus();
  });

  return (
    <div
      role="dialog"
      aria-label="Malawi Page 1"
      id="malawiP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        Electronic Medical Record (EMR) Coverage in Malawi, 2012-2023
      </header>
      <section className="centered-div">
        <div
          className="malawi-img-container"
          name="imgcontainer"
          aria-label="Electronic medical record coverage building up across 2012, 2018, 2019, and present day 2023."
          role="note"
          tabIndex={0}
          width={"100%"}
        >
          <section>
            <div className="image-frame">
              <img
                aria-hidden={true}
                width={"100%"}
                src={img2012}
                alt="Electronic Medical Record (EMR) Coverage in Malawi, 2012"
              />
            </div>
            <h1>2012</h1>
          </section>

          <section>
            <div className="image-frame">
              <img
                name="img2"
                aria-hidden={true}
                width={"100%"}
                src={img2018}
                alt="Electronic Medical Record (EMR) Coverage in Malawi, 2018"
              />
            </div>
            <h1>2018</h1>
          </section>

          <section>
            <div className="image-frame">
              <img
                name="img3"
                aria-hidden={true}
                width={"100%"}
                src={img2019}
                alt="Electronic Medical Record (EMR) Coverage in Malawi, 2019"
              />
            </div>
            <h1>2019</h1>
          </section>

          <section>
            <div className="image-frame">
              <img
                name="img4"
                aria-hidden={true}
                width={"100%"}
                src={img2023}
                alt="Electronic Medical Record (EMR) Coverage in Malawi, 2023"
              />
            </div>
            <h1>2023</h1>
          </section>
        </div>
      </section>
      <section className="note" tabIndex={0} name="note" role="note">
        <p>Note: this is specific to the HIV-ART EMR</p>
      </section>
    </div>
  );
};

export default MalawiPage1;
