import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import chart from "assets/images/CountryStories/Vietnam-Pg2-chart.png";

import "./CountryPages.scss";

const VietNamPage2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="note"
      id="namP2"
      name="namP2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        A Sustainable, Interoperable, and Country-owned Data Ecosystem
      </header>
      <section className="story-content">
        <div className="chart-div">
          <div className="image-frame">
            <img
              src={chart}
              id="chart"
              tabIndex={0}
              name="chart"
              role="img"
              alt="Timeline of the development of a sustainable, interoperable, and country-owned data ecosystem since PEPFAR support initated in 2004."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default VietNamPage2;
