import React from "react";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { chartColors } from "../charts";
import { getChartFontSize, getBarLabelPosition } from "common";
import "components/CountryStories/pages/CountryPages.scss";

const HaitiPageOne = () => {
  const theme = useSelector(selectCompliance).theme;
  const calcedFontSize = getChartFontSize();
  const chartOptions1 = {
    aria: {
      show: true,
      label: { general: { withTitle: 'This is a chart about "{title}".' } },
    },
    title: {
      show: false,
      text: "Haiti chart 1",
      left: "center",
    },
    grid: {
      show: true,
    },
    legend: {
      orient: "horizontal",
      bottom: 10,
      textStyle: {
        color: theme.color,
        fontSize: calcedFontSize,
        fontWeight: "bold",
      },
    },
    tooltip: { formatter: "{b}<br/>{c} ({a})" },
    color: chartColors,
    label: {
      show: true,
      position: getBarLabelPosition(),
      fontWeight: "bold",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        color: theme.color,
        fontSize: calcedFontSize,
        fontWeight: "bold",
      },
      data: ["TX_CURR", "TX_PVLS D", "TX_PVLS N"],
    },
    yAxis: {
      axisLabel: {
        color: theme.color,
        fontSize: calcedFontSize,
        fontWeight: "bold",
      },
    },
    series: [
      {
        name: "Aggregate Data System (ADS)",
        type: "bar",
        data: [132875, 99487, 86520],
        label: {
          fontSize: calcedFontSize,
          formatter: function (params) {
            return params.value.toLocaleString();
          },
        },
      },
      {
        name: "Patient Level EMR",
        type: "bar",
        data: [129995, 95285, 78763],
        label: {
          fontSize: calcedFontSize,
          formatter: function (params) {
            return params.value.toLocaleString();
          },
        },
      },
      {
        name: "National Data Repository (NDR)",
        type: "bar",
        data: [112933, 82647, 67955],
        label: {
          fontSize: calcedFontSize,
          formatter: function (params) {
            return params.value.toLocaleString();
          },
        },
      },
    ],
  };

  return (
    <div
      id="haitiPg1"
      aria-label="Haiti Page 1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor }}
    >
      <header className="story-title" tabIndex={0} name="title" role="note">
        Person-Centered Monitoring in Haiti​
      </header>
      <section>
        <div className="main-row">
          <div
            className="left-col"
            tabIndex={0}
            name="section1"
            role="note"
            style={{ background: theme.bgGradient }}
            aria-label="Comparison of patients on treatment from aggregate data system, patient level EMR system, and national data repository."
          >
            <div>
              <h6>
                Comparison of Patients on Treatment from (1) Aggregate Data
                System, <br /> (2) Patient Level EMR System and (3) National
                Data Repository
              </h6>
            </div>
            <ReactECharts
              option={chartOptions1}
              style={{ height: "85%" }}
              onChartReady={null}
              opts={{ renderer: "canvas" }}
            />
          </div>
          <div className="right-col" tabIndex={0} name="section2" role="note">
            <p>
              This figure shows the difference between the number of people on
              antiretroviral therapy (ART) from the (1) aggregate data system
              (MESI), (2) patient level EMRs, and (3) de-duplicated individual
              level data repository (SALVH). ​
            </p>
            <p>
              The de-duplicated patient data enabled the country to distinguish
              true PLHIV with interrupted ART from ones who silently transferred
              to another site to continue their treatment.
            </p>
            <p>
              Additionally, using the de-duplicated individual level data rather
              than the aggregate site level data resulted in a more accurate
              count of PLHIV on treatment (16% fewer), describing the actual
              programmatic gaps.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HaitiPageOne;
