import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import timeline from "assets/images/CountryStories/uk-timeline.png";

import "./CountryPages.scss";

const UkrainePage2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Ukraine Page 2"
      id="uk2"
      name="uk2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        PEPFAR Supports Development of Ukraine’s Public Health Info Systems
      </header>
      <section className="story-content">
        <div className="main-stack">
          <div className="image-frame">
            <img
              src={timeline}
              tabIndex={0}
              name="timeline"
              role="img"
              alt="Since 2012, PEPFAR has supported development of Ukraine's HIV public health information systems."
            />
          </div>
          <div className="legend">
            <div
              tabIndex={0}
              role="note"
              name="col1"
              aria-label="2012 thru 2015"
            >
              <ul>
                <li>National working group.</li>
                <li>
                  on HIV Medical Information System (MIS) development​ TOR for
                  HIV MIS.
                </li>
                <li>TOR for HIV MIS.</li>
                <li>
                  MoH Order No. 799 on pilot operation of unified e-system.
                </li>
              </ul>
            </div>
            <div
              tabIndex={0}
              role="note"
              name="col2"
              aria-label="2015 thru 2017"
            >
              <ul>
                <li>
                  HIV MIS development and pilot operation in three regions (1st
                  stage), and then nine regions (2nd stage).
                </li>
              </ul>
            </div>
            <div tabIndex={0} role="note" name="col3" aria-label="2018">
              <ul>
                <li>
                  HIV MIS rolled out to all 25 regions of Ukraine​ MoH Order No.
                  1251 On the introduction of national eHealth system.
                </li>
                <li>
                  MoH Order No. 1251 On the introduction of national eHealth
                  system.
                </li>
              </ul>
            </div>
            <div tabIndex={0} role="note" name="col4" aria-label="2019">
              <ul>
                <li>MAT module development.</li>
              </ul>
            </div>
            <div tabIndex={0} role="note" name="col5" aria-label="2020">
              <ul>
                <li>
                  Transfer of property rights to MoH’s Public Health Center.
                </li>
                <li>TB module development.</li>
              </ul>
            </div>
            <div tabIndex={0} role="note" name="col6" aria-label="2021">
              <ul>
                <li>Interoperability with eHealth.</li>
              </ul>
            </div>
            <div tabIndex={0} role="note" name="col7" aria-label="2022">
              <ul>
                <li>
                  HIV MIS renamed IS MSSD in recognition of progress on module
                  integration and industrial use.
                </li>
              </ul>
            </div>
            <div tabIndex={0} role="note" name="col8" aria-label="2023">
              <ul>
                <li>MAT module implementation.​</li>
                <li>DQA.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UkrainePage2;
