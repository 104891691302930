import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import ReactECharts from "echarts-for-react";
import { chartColors } from "../charts";
import { getChartFontSize, getChartTitleSize } from "common";

import "./CountryPages.scss";

const CaribbeanPage2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);
  const currentFontSize = getChartFontSize();

  const chartOptions = {
    aria: {
      show: false,
    },
    grid: { show: false, bottom: "10%" },
    title: {
      text: "Number of Viral Loads Taken by Month, Jan -July 2023",
      left: "center",
      textStyle: {
        fontSize: getChartTitleSize(),
      },
    },
    color: chartColors,
    label: {
      show: true,
      position: "inside",
      fontWeight: "bold",
    },
    xAxis: {
      type: "category",
      axisLabel: { fontSize: currentFontSize, fontWeight: "bold" },
      data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    yAxis: {
      type: "value",
      axisLabel: { fontSize: currentFontSize, fontWeight: "bold" },
      min: 90,
    },
    series: [
      {
        data: [110, 99, 140, 120, 145, 140, 144],
        type: "line",
        symbolSize: 15,
        label: { show: true, fontSize: currentFontSize },
        markLine: {
          lineStyle: {
            type: "dashed",
          },
          data: [[{ coord: ["Jan", "110"] }, { coord: ["Jul", "145"] }]],
        },
        markPoint: {
          data: [
            {
              type: "average",
            },
          ],
          symbol: "circle",

          symbolOffset: [0, "-20%"],
          itemStyle: {
            color: "red",
          },
        },
      },
    ],
  };

  return (
    <div
      role="dialog"
      aria-label="Caribbean Page 2"
      id="carP2"
      name="carP2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        TSIS-2-Care: Using Site-Level Data to Improve VL Monitoring, Jamaica,
        2023 ​
      </header>
      <section className="story-content">
        <div className="main-stack">
          <div className="row1">
            <div tabIndex={0} role="note" name="div1">
              <h4>Challenges</h4>
              <ul id="challenges">
                <li>Paper-based appointment records were inaccurate.</li>
                <li>
                  Patients were not identified in time to receive appointment
                  reminder calls​.
                </li>
                <li>
                  Patients were leaving the clinic without sample collection for
                  viral load test.
                </li>
                <li>
                  Systems to facilitate viral load tests prior to their clinical
                  consultations were inadequate.
                </li>
              </ul>
            </div>
            <div tabIndex={0} role="note" name="div2">
              <h4>Proposed Solution</h4>
              <p>
                Use of the{" "}
                <strong>Treatment Site Information System (TSIS 2.0)</strong> to
                generate lists of patients with upcoming appointments and
                flagging patients who are due for viral load testing.​
              </p>
              <h6>Process implemented April 2023:​</h6>
              <ul>
                <li>
                  Current register pulled in TSIS 2.0 each Thursday for
                  following week.
                </li>
                <li>Lists generated for appointments, VL uptake.</li>
                <li>Lists distributed to psychosocial and clinical staff​.</li>
                <li>
                  Reminder calls made and follow-up made at appointments​.
                </li>
              </ul>
            </div>
          </div>
          <div className="row2">
            <div
              className="keys"
              tabIndex={0}
              role="note"
              name="div3"
              style={{
                backgroundColor: theme.backgroundColor,
                color: theme.color,
              }}
            >
              <h4>
                <u>Key Achievements</u>
              </h4>
              <ul className="key-list">
                <li>
                  Accurate data being used for appointment reminder calls and
                  viral load monitoring.
                </li>
                <li>
                  Improved coordination in patient management and clinic flow.
                </li>
                <li>
                  Improved retention, viral load uptake and viral suppression.
                </li>
                <li>
                  19% increase in the number of viral load tests conducted from
                  baseline in April 2023.
                </li>
                <li>
                  Achieved highest performance on viral load coverage (PVLS_N)
                  and viral load suppression (PVLS_D) since start of PEPFAR
                  support.
                </li>
              </ul>
            </div>
            <div
              className="chart-div"
              style={{ background: theme.bgGradient }}
              tabIndex={0}
              name="chart1"
              role="note"
            >
              <ReactECharts
                style={{ width: "100%", height: "90%" }}
                option={chartOptions}
                onChartReady={null}
                opts={{ renderer: "canvas" }}
              />
            </div>
          </div>
          <div className="footer">
            This activity was supported by the President’s Emergency Plan for
            AIDS Relief (PEPFAR) through the U.S. Centers or Disease Control and
            Prevention (CDC) and the implementing partner University of
            California, San Francisco (UCSF)
          </div>
        </div>
      </section>
    </div>
  );
};

export default CaribbeanPage2;
