import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import img1 from "assets/images/CountryStories/uganda-page1.png";

import "./CountryPages.scss";

const UgandaPage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Uganda Page 1"
      id="ugP1"
      name="ugP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        PEPFAR Uganda’s Evolution: Transition to Using the National Reporting
        Systems​
      </header>
      <section className="story-content">
        <div className="img-container">
          <div className="image-frame">
            <img src={img1} alt="page 1" name="img1" role="img" tabIndex={0} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default UgandaPage1;
