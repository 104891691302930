import React, { useEffect, useState } from "react";
import { MDBAlert } from "mdbreact";
import * as common from "./common";

import "./App.scss";
import "./Maint.scss";
import Header from "./components/Header/Header";
import { BrowserRouter } from "react-router-dom";
import MainContentArea from "./components/MainContentArea/MainContentArea";
import DropDownNavMenu from "./components/DropDownNavMenu/DropDownNavMenu";
import Backdrop from "./components/Backdrop/Backdrop";
import maintIcon from "./assets/images/maintenance-icon.png";
import maintIconWebp from "./assets/images/WEBP/maintenance-icon.webp";
import WebpElement from "components/WebpElement/WebpElement";
import { initDaa } from "stateMgmt/daaSlice";
import { useDispatch } from "react-redux";
import logger from "services/LoggingService";

const mdbAlertClasses =
  "text-center fixed-bottom user-alert-show maintenance-banner";

function App() {
  // Reset Storage
  // sessionStorage.clear();
  // localStorage.clear();
  const dispatch = useDispatch();

  const [dropDownNavMenuOpen, setDropDownNavMenuOpen] = useState(false);
  const [maintenanceInfo, setMaintenanceInfo] = useState({
    offline: false,
    showSpotlightMaintNotification: false,
    maintenanceMessage1: "",
    maintenanceMessage2: "",
    spotlightMaintenanceNotification: "",
  });

  useEffect(() => {
    logger.debug(`RUNNING ENV ${process.env.REACT_APP_ENV}`);
    logger.debug(`Logging level: ${logger.loggingLevel()}`);

    const maintPath = common.getS3Url() + "maint_message.json";
    const bypass = localStorage.getItem("BypassMaint");

    if (bypass !== "true") {
      // GET MAINT MESSAGE
      fetch(maintPath, {
        method: "GET",
        cache: "no-cache",
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            maintenanceInfo.offline !== data.Spotlight.offline ||
            maintenanceInfo.showSpotlightMaintNotification !==
              data.Spotlight.showMaintenanceNotification
          ) {
            setMaintenanceInfo({
              offline: data.Spotlight.offline,
              showSpotlightMaintNotification:
                data.Spotlight.showMaintenanceNotification,
              maintenanceMessage1: data.Spotlight.offLineMessage,
              maintenanceMessage2: data.Spotlight.maintenanceNotification,
              spotlightMaintenanceNotification:
                data.Spotlight.maintenanceNotification,
            });
          }
        })
        .catch(() => {
          logger.debug(
            "Maint File is missing or connection is bad. Defaulting to normal login."
          );
        });
    }

    dispatch(initDaa());
  }, []);

  const backdropClickHandler = () => {
    setDropDownNavMenuOpen(false);
  };

  const dropDownMenuToggleClickHandler = () => {
    setDropDownNavMenuOpen(!dropDownNavMenuOpen);
  };

  const renderNormal = () => {
    let backdrop;

    if (dropDownNavMenuOpen) {
      backdrop = <Backdrop click={() => backdropClickHandler()} />;
    }

    return (
      <BrowserRouter>
        <div>
          <Header dropDownMenuClickHandler={dropDownMenuToggleClickHandler} />
          <DropDownNavMenu
            show={dropDownNavMenuOpen}
            dropDownMenuClickHandler={dropDownMenuToggleClickHandler}
          />
          {backdrop}
          <MainContentArea />
          {maintenanceInfo.showSpotlightMaintNotification ? (
            <MDBAlert
              id="notification-banner"
              data-testId="alert"
              color="danger"
              dismiss
              className={mdbAlertClasses}
            >
              {maintenanceInfo.spotlightMaintenanceNotification}
            </MDBAlert>
          ) : (
            <div></div>
          )}
        </div>
      </BrowserRouter>
    );
  };

  const renderOffline = (maintenanceMessage1, maintenanceMessage2) => {
    return (
      <div className="error-page-wrapper">
        <div className="content-container in">
          <div style={{ width: "100%", textAlign: "center", margin: "auto" }}>
            <h1>Maintenance</h1>
          </div>
          <br />
          <div className="maintenance-img">
            <WebpElement
              className="maintenance-img"
              src={maintIcon}
              webp={maintIconWebp}
              alt="Maint"
              testId="MaintImg"
            />
          </div>
          <br />
          <div className="maintenance-banner">{maintenanceMessage1}</div>
          <hr />
          {/* <div className="clearfix"></div> */}
          <div className="maintenance-text">{maintenanceMessage2}</div>
        </div>
      </div>
    );
  };

  return maintenanceInfo.offline
    ? renderOffline(
        maintenanceInfo.maintenanceMessage1,
        maintenanceInfo.maintenanceMessage2
      )
    : renderNormal();
}

export default App;
