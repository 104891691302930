import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import redArrow from "assets/images/CountryStories/ethRedArrow.png";
import blueArrow from "assets/images/CountryStories/ethBlueArrow.png";
import greenArrow from "assets/images/CountryStories/ethGreenArrow.png";
import skillsIcon from "assets/images/CountryStories/ethSkillsIcon.png";
import sysIcon from "assets/images/CountryStories/ethSysIcon.png";
import policyIcon from "assets/images/CountryStories/ethPolicyIcon.png";
import infoIcon from "assets/images/CountryStories/ethInfoIcon.png";

import "./CountryPages.scss";

const EthiopiaPage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="note"
      aria-label=""
      id="ethP1"
      name="ethP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        PEPFAR&apos;s Two Decades of Collaboration For Health Organization in
        Ethiopia
      </header>
      <section className="story-content">
        <div className="main-row">
          <div className="col2" tabIndex={0} role="note" name="col2">
            <div className="arrow-frame">
              <div></div>
              <div className="row1">
                <img src={policyIcon} className="icon" aria-hidden={true} />
                <strong>Policy & Governance</strong>{" "}
              </div>
            </div>
            <div className="arrow-frame">
              <div className="image-frame">
                <img src={blueArrow} alt="Our Pre Pepfar state" />
              </div>
              <div className="row2">
                <div>
                  <ul>
                    <li>
                      Lack of national policy & guidelines on digital health &
                      data use.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="arrow-frame">
              <div className="image-frame">
                <img
                  src={greenArrow}
                  alt="developed through 20 years of Pepfar"
                />
              </div>
              <div className="row3">
                <div>
                  <ul>
                    <li>
                      Supportive policy environment & clear strategy digital
                      health & data use​.
                    </li>
                    <li>
                      Better coordination, collaboration & Integration with
                      programs​.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="arrow-frame">
              <div className="image-frame">
                <img src={redArrow} alt="the current outcomes" />
              </div>
              <div className="row4">
                <div>
                  <ul>
                    <li>
                      PEPFAR contributes to overall health system strengthening.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col3" tabIndex={0} role="note" name="col3">
            <div className="row1">
              <img src={skillsIcon} className="icon" aria-hidden={true} />
              <strong>Skilled Work Force</strong>
            </div>
            <div className="row2">
              <ul>
                <li>
                  Lack of dedicated health informatics work force in the health
                  system.
                </li>
              </ul>
            </div>
            <div className="row3">
              <ul>
                <li>Health informatics positions created at all levels.</li>
                <li>Trained  work force on health informatics.</li>
              </ul>
            </div>
            <div className="row4">
              <ul>
                <li>
                  Evidence based decision making & better health outcomes.​
                </li>
              </ul>
            </div>
          </div>
          <div className="col4" tabIndex={0} role="note" name="col4">
            <div className="row1">
              <img src={sysIcon} className="icon" aria-hidden={true} />
              <strong>Effective Information System</strong>
            </div>
            <div className="row2">
              <ul>
                <li>Fragmented systems.</li>
                <li>
                  Old data rooms & reliance on paper-based recording &
                  reporting.
                </li>
              </ul>
            </div>
            <div className="row3">
              <ul>
                <li>
                  Streamlining process – standardized, simplified, & integrated.
                </li>
                <li>
                  Leveraged technologies – national, surveillance, community, &
                  patient level information systems.
                </li>
              </ul>
            </div>
            <div className="row4">
              <ul>
                <li>
                  Improved use of quality data towards HIV Epidemic Control.
                </li>
              </ul>
            </div>
          </div>
          <div className="col5" tabIndex={0} role="note" name="col5">
            <div className="row1">
              <img src={infoIcon} className="icon" aria-hidden={true} />
              <strong>Information Use</strong>
            </div>
            <div className="row2">
              <ul>
                <li>
                  Fragmented data quality, review & analytics at all levels.
                </li>
              </ul>
            </div>
            <div className="row3">
              <ul>
                <li>Significant improvement in data quality.</li>
                <li>
                  Advanced data analytics platforms & culture of data use.
                </li>
              </ul>
            </div>
            <div className="row4">
              <ul>
                <li>Improved public health outcomes & practices.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EthiopiaPage1;
