import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import map from "assets/images/CountryStories/zambiaMap.png";

import "./CountryPages.scss";

const ZambiaPag2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Zambia Page 1"
      id="zamP2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        SmartCare Roll Out with PEPFAR Support
      </header>
      <section className="story-content">
        <div className="main-row">
          <div className="image-frame">
            <img
              src={map}
              tabIndex={0}
              name="img1"
              role="img"
              alt="Map of Zambia showcasing the spread of Smartcare within facilities across the country."
            />
          </div>
          <div className="text-block" tabIndex={0} name="textBlock" role="note">
            <p>
              <h4>SmartCare launched in 2005</h4>
              <ul>
                <li>
                  Covering 10 health facilities with less than 300 clients on
                  ART.
                </li>
              </ul>
            </p>

            <p>
              <h4>SmartCare Status in 2023</h4>
              <ul>
                <li>
                  Nearly 1,700 facilities with SmartCare in 116 districts.​
                </li>
                <li>Reaching over 1,100,000 clients on ART (unduplicated)</li>
                <li>
                  Improved data-driven decision-making leading to targeted
                  interventions, resulting in improved patient care and health
                  outcomes. ​
                </li>
                <li>
                  Roll out of web-based SmartCare Plus with a global address
                  book functionality improving client tracking and real time
                  data.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ZambiaPag2;
