import React, { useState, useEffect } from "react";
import { Navigation, Pagination, A11y, EffectFade, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ally from "ally.js";
import Divider from "./pages/Divider";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import "swiper/css/effect-fade";
import logger from "services/LoggingService";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { v4 as uuid } from "uuid";

/// ////////////////////// Story Pages /////////////////////////
// ------------------------------------------------------------
import CameroonPage1 from "./pages/Camerron-1";
import CameroonPage2 from "./pages/Camerron-2";
import CaribbeanPage1 from "./pages/Caribbean-1";
import CaribbeanPage2 from "./pages/Caribbean-2";
import CDIPage1 from "./pages/CDI-1";
import CDIPage2 from "./pages/CDI-2";
import DominicanRepublic1 from "./pages/DominicanReplublic-1";
import DominicanRepublic2 from "./pages/DominicanRepublic-2";
import EthiopiaPage1 from "./pages/Ethiopia-1";
import HaitiPageOne from "./pages/Haiti-1";
import HaitiPageTwo from "./pages/Haiti-2";
import KenyaPage1 from "./pages/Kenya-1";
import KenyaPage2 from "./pages/Kenya-2";
import KenyaPage3 from "./pages/Kenya-3";
import MalawiPage1 from "./pages/Malawi-1";
import MalawiPageTwo from "./pages/Malawi-2";
import TogoPage1 from "./pages/Togo-1";
import TogoPage2 from "./pages/Togo-2";
import TogoPage3 from "./pages/Togo-3";
import UgandaPage1 from "./pages/Uganda-1";
import UgandaPage2 from "./pages/Uganda-2";
import UkrainePage1 from "./pages/Ukraine-1";
import UkrainePage2 from "./pages/Ukraine-2";
import UkrainePage3 from "./pages/Ukraine-3";
import VietNamPage1 from "./pages/VietNam-1";
import VietNamPage2 from "./pages/VietNam-2";
import ZambiaPag1 from "./pages/Zambia-1";
import ZambiaPag2 from "./pages/Zambia-2";
import ZambiaPag3 from "./pages/Zambia-3";

/// ////////////////////// Story Dividers /////////////////////////
// ------------------------------------------------------------
import cameroonIcon from "assets/images/CountryStories/Dividers/Cameroon.png";
import caribbeanIcon from "assets/images/CountryStories/Dividers/Caribbean.png";
import cdiIcon from "assets/images/CountryStories/Dividers/Cote-diovoire.png";
import dominicanIcon from "assets/images/CountryStories/Dividers/Dominican.png";
import kenyaIcon from "assets/images/CountryStories/Dividers/Kenya.png";
import malawiIcon from "assets/images/CountryStories/Dividers/Malawi.png";
import togoIcon from "assets/images/CountryStories/Dividers/Togo.png";
import haitiIcon from "assets/images/CountryStories/Dividers/Haiti.png";
import zambiaIcon from "assets/images/CountryStories/Dividers/Zambia.png";
import ukraineIcon from "assets/images/CountryStories/Dividers/Ukraine.png";
import vietnamIcon from "assets/images/CountryStories/Dividers/Vietnam.png";
import ethiopiaIcon from "assets/images/CountryStories/Dividers/Ethiopia.png";
import ugandaIcon from "assets/images/CountryStories/Dividers/Uganda.png";

const cameroonDivider = (
  <Divider story={0} headerImg={cameroonIcon} content="Cameroon's Journey" />
);
const caribbeanDivider = (
  <Divider story={1} headerImg={caribbeanIcon} content="Caribbean's Journey" />
);
const cdiDivider = (
  <Divider
    story={2}
    headerImg={cdiIcon}
    content="C&ocirc;te d'Ivoire's Journey"
  />
);
const dominicanDivider = (
  <Divider
    story={3}
    headerImg={dominicanIcon}
    content="Dominican Republic's Journey"
  />
);
const ethiopiaDivider = (
  <Divider story={4} headerImg={ethiopiaIcon} content="Ethiopia's Journey" />
);
const haitiDivider = (
  <Divider story={5} headerImg={haitiIcon} content="Haiti's Journey" />
);
const kenyaDivider = (
  <Divider story={6} headerImg={kenyaIcon} content="Kenya's Journey" />
);
const malawiDivider = (
  <Divider story={7} headerImg={malawiIcon} content="Malawi's Journey" />
);
const togoDivider = (
  <Divider story={8} headerImg={togoIcon} content="Togo's Journey" />
);
const ugandaDivider = (
  <Divider story={9} headerImg={ugandaIcon} content="Uganda's Journey" />
);
const ukraineDivider = (
  <Divider story={10} headerImg={ukraineIcon} content="Ukraine's Journey" />
);
const vietnamDivider = (
  <Divider story={11} headerImg={vietnamIcon} content="Vietnam's Journey" />
);
const zambiaDivider = (
  <Divider story={12} headerImg={zambiaIcon} content="Zambia's Journey" />
);

// ------------------------------------------------------------
/// ////////////////////////////////////////////////////////////

let currentPage;

export const slides = [
  cameroonDivider,
  CameroonPage1,
  CameroonPage2,
  caribbeanDivider,
  CaribbeanPage1,
  CaribbeanPage2,
  cdiDivider,
  CDIPage1,
  CDIPage2,
  dominicanDivider,
  DominicanRepublic1,
  DominicanRepublic2,
  ethiopiaDivider,
  EthiopiaPage1,
  haitiDivider,
  HaitiPageOne,
  HaitiPageTwo,
  kenyaDivider,
  KenyaPage1,
  KenyaPage2,
  KenyaPage3,
  malawiDivider,
  MalawiPage1,
  MalawiPageTwo,
  togoDivider,
  TogoPage1,
  TogoPage2,
  TogoPage3,
  ugandaDivider,
  UgandaPage1,
  UgandaPage2,
  ukraineDivider,
  UkrainePage1,
  UkrainePage2,
  UkrainePage3,
  vietnamDivider,
  VietNamPage1,
  VietNamPage2,
  zambiaDivider,
  ZambiaPag1,
  ZambiaPag2,
  ZambiaPag3,
];

// ********************** CountryModal Component **********************
const CountryModal = () => {
  const theme = useSelector(selectCompliance).theme;
  const numSlides = slides.length;
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const tabbableElements = ally.query.tabbable({
    context: ".CountryContainer",
    includeContext: true,
    strategy: "quick",
  });

  const pageHeaders = tabbableElements.filter((el) => {
    return el.className === "story-title" || el.className === "divider";
  });

  const setNextTab = () => {
    const direction =
      controlledSwiper.realIndex > controlledSwiper.previousRealIndex
        ? "forward"
        : "reverse";

    currentPage = direction === "forward" ? currentPage + 1 : currentPage - 1;
    if (currentPage === numSlides) {
      currentPage = numSlides - 1;
    } else if (currentPage < 0) {
      currentPage = 0;
    }

    const nextHeader = pageHeaders[currentPage];

    try {
      ally.element.focus(nextHeader);
    } catch {
      logger.error("FOCUS FAILED");
    }
  };

  useEffect(() => {
    currentPage = 0;
  });

  return (
    <div
      className="CountryContainer"
      style={{
        backgroundColor: theme.backgroundColor,
        color: theme.color,
      }}
    >
      <Swiper
        style={{
          "--swiper-navigation-color": "black",
          "--swiper-navigation-size": "35px",
        }}
        modules={[Navigation, Pagination, A11y, EffectFade, Keyboard]}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        speed={700}
        slidesPerView={1}
        keyboard={{
          enabled: true,
        }}
        navigation
        pagination={{ type: "progressbar", clickable: true }}
        grabCursor={true}
        onSwiper={setControlledSwiper}
        onSlideChange={() => {
          setNextTab();
        }}
        onReachEnd={() => {}}
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={uuid()} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
// ********************************************************************

export default CountryModal;
