import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import img1 from "assets/images/CountryStories/CDI-2.png";
import "./CountryPages.scss";

const CDIPage2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Cote Page 2"
      id="cdiP2"
      name="cdiP2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        C&ocirc;te &#271;Ivoire – HIV HIS PEPFAR landscape vision
      </header>
      <section className="story-content">
        <div className="image-frame">
          <img
            name="img1"
            role="img"
            src={img1}
            alt="C&ocirc;te &#271;Ivoire's HIV HIS PEPFAR landscape across the national, subnational, and facility levels."
            tabIndex={0}
          />
        </div>
      </section>
    </div>
  );
};

export default CDIPage2;
