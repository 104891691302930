import React, { useRef } from "react";
// import SMXChart from "components/Widgits/SMXChart";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { chartColors } from "../charts";

import "./CountryPages.scss";

const TogoPage3 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);
  const chartOptions1 = {
    aria: {
      show: false,
    },
    grid: { show: true, top: 80 },
    title: {
      text: "Togo Interruption in Treatment under 3% and continuously decreasing",
      left: "center",
      textStyle: {
        color: theme.color,
        fontSize: "1.0rem",
      },
    },
    legend: {
      orient: "horizontal",
      bottom: 0,
      textStyle: { color: theme.color },
    },
    tooltip: {
      formatter: "{b}<br />{c}%",
      textStyle: {
        fontWeight: "bold",
      },
    },
    color: chartColors,
    xAxis: [
      {
        type: "category",
        axisTick: { show: true },
        axisLabel: { fontSize: 12, fontWeight: "bold" },
        data: [
          "2021Q4",
          "2022Q1",
          "2022Q2",
          "2022Q3",
          "2022Q4",
          "2023Q1",
          "2023Q2",
          "2023Q3",
        ],
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: { fontSize: 12, fontWeight: "bold" },
        position: "left",
        min: 0,
        max: 3,
      },
    ],
    series: [
      {
        type: "line",
        label: {
          show: true,
          distance: 20,
          align: "center",
          verticalAlign: "middle",
          fontSize: "1.0rem",
          fontWeight: "800",
          formatter: "{c}%",
        },
        emphasis: {
          focus: "series",
        },
        data: [2.0, 0.5, 2.0, 1.5, 3, 2.0, 1.5, 2.0],
      },
    ],
  };

  const chartOptions2 = {
    aria: {
      show: false,
    },
    grid: { show: false, bottom: "10%" },
    title: {
      text: "Viral Load Coverage has seen a dramatic increase",
      left: "center",
      textStyle: {
        color: theme.color,
        fontSize: "1.0rem",
      },
    },
    legend: {
      orient: "horizontal",
      bottom: 0,
      // bottom: 10,
      textStyle: { color: theme.color },
    },
    tooltip: {
      formatter: "{b}<br />{c}%",
      textStyle: {
        fontWeight: "bold",
      },
    },
    color: chartColors,
    xAxis: [
      {
        type: "category",
        axisTick: { show: true },
        axisLabel: { fontSize: 12, fontWeight: "bold" },
        data: [
          "2021Q2",
          "2021Q3",
          "2021Q4",
          "2022Q1",
          "2022Q2",
          "2022Q3",
          "2022Q4",
          "2023Q1",
          "2023Q2",
          "2023Q3",
        ],
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: { fontSize: 12, fontWeight: "bold" },
        position: "left",
        min: 0,
        max: 100,
      },
    ],
    series: [
      {
        type: "bar",
        barGap: 0,
        label: {
          show: true,
          position: "inside",
          distance: 25,
          align: "center",
          verticalAlign: "middle",
          fontSize: "1.0rem",
          fontWeight: "800",
          formatter: "{c}%",
        },
        emphasis: {
          focus: "series",
        },
        data: [70, 74, 85, 87, 89, 92, 90, 91, 88, 89],
      },
    ],
  };

  return (
    <div
      role="dialog"
      aria-label="TogoPage3"
      id="tgPg3"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        The Results and Impact of the Implementation of the eTracker are Evident
        ​
      </header>
      <section className="story-content">
        <div className="main-row">
          <div className="charts">
            <div style={{ height: "45%" }}>
              <div
                className="chart-div"
                style={{ background: theme.bgGradient }}
                tabIndex={0}
                name="chart1"
                role="note"
                aria-label="Since 2021, interruption in treatment has continously decreased and is under 3%. Meanwhile viral load coverage has experienced a dramatic increase in the same time period."
              >
                <ReactECharts
                  style={{ height: "100%" }}
                  option={chartOptions1}
                  onChartReady={null}
                  opts={{ renderer: "canvas" }}
                />
              </div>
            </div>
            <div style={{ height: "45%" }}>
              <div
                className="chart-div"
                style={{ background: theme.bgGradient }}
                tabIndex={0}
                name="chart1"
                role="note"
                aria-label=" chart 2"
              >
                <ReactECharts
                  style={{ height: "100%" }}
                  option={chartOptions2}
                  onChartReady={null}
                  opts={{ renderer: "canvas" }}
                />
              </div>
            </div>
          </div>
          <div tabIndex={0} name="section1" role="note">
            <ul>
              <li>
                <strong>
                  The eTracker, in combination with other best practices{" "}
                </strong>
                (implication of community health workers and peer navigators),
                has tremendously{" "}
                <strong>
                  improved and optimized routine facility activities
                </strong>{" "}
                through patient tracking (for appointment reminders and missed
                appointment, index testing, viral load test eligibility etc.)
                and <strong>ultimately reporting.</strong>
              </li>
            </ul>
            <h4>Lessons Learned:</h4>
            <ul>
              <li>
                <strong>Leading with evidence and data</strong> allowed us to
                demonstrate the positive impact of the etracker on key program
                results and to advocate for its scale up.
              </li>
              <li>
                <strong>
                  The strong collaboration with Togo Ministry of Health and the
                  Global Fund
                </strong>{" "}
                have contributed to a successful scale up of the patient
                tracker​.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TogoPage3;
