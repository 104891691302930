import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import data1 from "assets/images/CountryStories/ugandaData1.png";
import data2 from "assets/images/CountryStories/ugandaData2.png";
import arrow from "assets/images/CountryStories/ugandaArrow.png";

import "./CountryPages.scss";

const UgandaPage2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Uganda Page 2"
      id="ugP2"
      name="ugP2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        20 Years of Progress through PEPFAR
      </header>
      <section className="story-content">
        <div
          className="main-stack"
          style={{
            backgroundColor: theme.backgroundColor,
            color: theme.color,
          }}
        >
          <div className="subtitle">
            <h4>Uganda&apos;s Current Process Flow</h4>{" "}
          </div>
          <div className="main-row">
            <div className="col1" tabIndex={0} name="col1" role="note">
              <div className="col-1-hdr">
                <strong>Health Management Information System</strong>
              </div>
              <ul aria-label="MoH's DHIS2 based HMIS">
                <li>
                  IPs work with health facilities and district health teams to
                  compile and enter data into eHMIS on quarterly, monthly and
                  weekly basis
                </li>
              </ul>
              <strong>HIBRID</strong>
              <ul aria-label="PEPFAR Uganda's own DHIS2 system">
                <li>
                  Collect data missing in Ministry of Health/Health Management
                  Information System
                </li>
                <li>
                  Implementing Partners work with sites at HF, community,
                  above-site level to collect and enter the data quarterly,
                  semiannually and annually
                </li>
                <li>Include missing age disaggregates and prevention</li>
                <li>National level indicators; e.g. lab, stock, HSS</li>
                <li>DREAMS specific disaggregations</li>
              </ul>
              <strong>OVC MIS</strong>
              <p>
                System in MGLSD (Ministry of Gender, Labor and Social
                Development)
              </p>
              <p>
                IPs work with district level community teams to compile and
                enter data on a quarterly basis
              </p>
            </div>
            <div className="col2">
              <div className="image-frame">
                <img id="img1" src={data1} alt="" aria-hidden={true} />
              </div>
            </div>
            <div className="col3" tabIndex={0} name="col3" role="note">
              <div
                className="page-frame"
                style={{
                  backgroundColor: theme.backgroundColor,
                  color: theme.color,
                }}
              >
                <ul aria-label="M&E Agents Initial Data Review and Feedback to IPs">
                  <li>
                    Data from all the systems are initially extracted, compiled
                    against the DAPTS* list, reviewed and feedback provided to
                    IPs by M&E agents
                  </li>
                  <li>
                    IPs work with sites to make corrections and improve on
                    reporting rates
                  </li>
                </ul>
              </div>
              <div
                className="page-frame"
                style={{
                  backgroundColor: theme.backgroundColor,
                  color: theme.color,
                }}
              >
                <ul aria-label="MOH Led Joint Data Review">
                  <li>
                    Data from MOH HMIS is again extracted by M&E agents,
                    validated and a clean dataset generated
                  </li>
                  <li>
                    M&E Agents then obtain the HIV program data for reporting
                  </li>
                </ul>
              </div>
              <div
                className="page-frame"
                style={{
                  backgroundColor: theme.backgroundColor,
                  color: theme.color,
                }}
              >
                <ul aria-label="PEPFAR Data Synchronization">
                  <li>
                    A final dataset is now generated from all the sources and
                    profiled again against the DAPTS* list
                  </li>
                  <li>
                    Data from HMIS and HIBRID is synchronized to compare
                    disaggregations (+/- 5% delta)
                  </li>
                </ul>
              </div>
              <div
                className="page-frame"
                style={{
                  backgroundColor: theme.backgroundColor,
                  color: theme.color,
                }}
              >
                <ul aria-label="Final Data Review and Approval">
                  <li>Review meeting with USG technical team</li>
                  <li>Review meeting with IPs to internally approve data</li>
                  <li>Narratives are developed by IPs using the data</li>
                </ul>
              </div>
            </div>
            <div className="divider">
              <div className="image-frame">
                <img id="arrow" src={arrow} alt="" aria-hidden={true} />
              </div>
            </div>
            <div className="col4" tabIndex={0} name="col4" role="note">
              <div className="image-frame">
                <img id="img2" src={data2} alt="" aria-hidden={true} />
              </div>
              <div>
                <ul aria-label="Data Coding and Upload">
                  <li>
                    Data is coded, shaped and prepared for loading into PEPFAR
                  </li>
                  <li>Upload in dev-de; data reviewed</li>
                  <li>R-scripts run; violations documented</li>
                  <li>Data is uploaded into DATIM4U</li>
                  <li>USG teams and IPs review and submit/approve data</li>
                  <li>
                    If required, final corrections made for final upload to
                    DATIM4U
                  </li>
                  <li>USG conducts de-dup; approves to global</li>
                  <li>Data submitted to DATIM</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer" tabIndex={0} name="footer" role="note">
            * &quot;DAPTS&quot; (Data Accountability Platform for Type of
            Support) is the list of IMs and their associated sites, support
            type, if DREAMS site, etc. compiled during COP process
          </div>
        </div>
      </section>
    </div>
  );
};

export default UgandaPage2;
