import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import orangeArrow from "assets/images/right-arrow-orange.png";
import greenArrow from "assets/images/right-arrow-green.png";

import "./CountryPages.scss";

const ZambiaPag1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Zambia Page 1"
      id="zamP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        20 years of PEPFAR support towards data generation
      </header>
      <section className="story-content">
        <div className="main-row">
          <div className="col1" tabIndex={0} name="section1" role="note">
            <div>
              <h3>2004</h3>
            </div>
            <div>
              <p style={{ textAlign: "center" }}>
                Paper dependent and labor-intensive system with untimely data. ​
              </p>
            </div>
            <div>
              <h4 style={{ color: "#7a591a" }}>
                Data capture was largely paper dependent before PEPFAR support.​
              </h4>
              <ul>
                <li>
                  There was no comprehensive routine data on HIV services.​
                </li>
                <li>Inability to effectively monitor the patients.​</li>
                <li>Limited electronic client focused tracking.</li>
              </ul>
            </div>
          </div>
          <div className="arrowDiv">
            <img src={orangeArrow} className="arrow" aria-hidden={true} />
          </div>
          <div className="col2" tabIndex={0} name="section2" role="note">
            <div>
              <h3>2005 - 2019</h3>
            </div>
            <div>
              <p style={{ textAlign: "center" }}>
                PEPFAR supported the scale up and integration of electronic
                health systems​.
              </p>
            </div>
            <div>
              <h4 style={{ color: "#7a591a" }}>
                Scale up, integration and expansion of electronic data
                management systems.
              </h4>
              <ul>
                <li>
                  SmartCare: from 10 facilities in 2005 to nearly 1,700 in 2023
                  covering nearly 1.1 million clients on ART.
                </li>
                <li>
                  eLMIS: building a stronger supply chain and logistics
                  management system.
                </li>
                <li>Disalab: Enhancing lab connectivity and coverage.</li>
                <li>
                  Integration of DHIS2 into HMIS for data management (September
                  2009-January 2016):​
                  <ul>
                    <li>Streamlined collection and analysis of health data.</li>
                    <li>
                      Improved data availability and use for comprehensive
                      program case finding, treatment and viral load coverage
                      strategies​.
                    </li>
                    <li>Improved inputs for PLHIV estimation.​</li>
                  </ul>
                </li>
                <li>
                  ZAMPHIA 2016 survey: Comprehensive picture of Zambia’s
                  progress towards 90-90-90: 71-87-89​.
                </li>
              </ul>
            </div>
          </div>
          <div className="arrowDiv">
            <img src={greenArrow} className="arrow" aria-hidden={true} />
          </div>
          <div className="col3" tabIndex={0} name="section3" role="note">
            <div>
              <h3>2020 - 2023</h3>
            </div>
            <div>
              <p style={{ textAlign: "center" }}>
                PEPFAR supported robust electronic health and surveillance
                systems​.
              </p>
            </div>
            <div>
              <h4 style={{ color: "#7a591a" }}>
                Increased data visualization
                <br /> and use​.
              </h4>
              <ul>
                <li>
                  Targeted case finding and recency surveillance for new
                  infections.​
                </li>
                <li>
                  Enhanced SmartCare for treatment cohort monitoring and
                  retention. Enhanced routine data use for program monitoring.​
                </li>
                <li>
                  Countrywide lab and logistics system for increased viral load
                  monitoring​.
                </li>
                <li>
                  2021 PHIA indicate Zambia is near achieving 95-95-95 goals:
                  89-98-96​.
                </li>
                <li>
                  Data from SmartCare, logistics, lab, human resource and health
                  information systems start to feed into the National Data
                  Warehouse for data analysis, visualization, and decision
                  making.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ZambiaPag1;
