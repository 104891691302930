import React, { useRef } from "react";
import ReactECharts from "echarts-for-react";
import { chartColors } from "../charts";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import img1 from "assets/images/CountryStories/KenyaPage2Img1.png";
import img2 from "assets/images/CountryStories/KenyaPage2Img2.png";
import { getChartFontSize } from "common";
import "./CountryPages.scss";

const KenyaPage2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);
  const calcedFontSize = getChartFontSize();

  const chartOptions = {
    aria: {
      show: false,
    },
    height: "65%",
    color: chartColors,
    label: {
      show: true,
      position: "inside",
      fontWeight: "bold",
      formatter: "{c}%",
      fontSize: calcedFontSize,
    },
    legend: {
      orient: "horizontal",
      top: 10,
      textStyle: { fontSize: calcedFontSize, fontWeight: "bold" },
    },
    xAxis: {
      type: "category",
      axisLabel: { fontSize: calcedFontSize, fontWeight: "bold" },
      data: ["Y2020", "Y2021", "Y2022", "Y2023"],
    },
    yAxis: {
      type: "value",
      axisLabel: { fontSize: calcedFontSize, fontWeight: "bold" },
      min: 50,
    },
    series: [
      {
        name: "Supressed %",
        data: [94.29, 94.76, 95.05, 95.73],
        type: "bar",
        stack: "x",
      },
      {
        name: "Non Supressed %",
        data: [5.71, 5.24, 4.95, 4.27],
        type: "bar",
        stack: "x",
      },
    ],
  };

  return (
    <div
      role="dialog"
      aria-label="Kenya Page 2"
      id="kenP2"
      name="kenP2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        Early Infant Diagnosis – Viral Load Data Exchange, TAT and Suppression​
      </header>
      <section className="story-content">
        <div className="main-row">
          <div className="col1" tabIndex={0} name="col1" role="note">
            <div className="col1Row1">
              <h5>
                VL suppression has increased from 94.3% in 2020 to 95.7% in
                2023.
              </h5>
            </div>
            <div className="image-frame">
              <img src={img1} id="img1" name="img1" aria-hidden={true} />
            </div>
            <div className="col1Row3">
              EID-VL Integration with registries and key systems for seamless
              data exchange improving quality and efficiency in availing
              results.
            </div>
          </div>
          <div className="col2" tabIndex={0} name="col2" role="note">
            <div className="col2Row1">
              <h5>Sample Testing Turnaround Time.</h5>
            </div>
            <div className="image-frame">
              <img src={img2} id="img2" name="img2" aria-hidden={true} />
            </div>
            <div className="col2Row3">
              Sample testing turnaround time (TAT) has improved over time from
              an average of 43 days in 2014 to 16 days in 2023.
            </div>
            <div className="col2Row4">Yearly Trend in VL Supression.</div>
            <div className="col2Row5">
              <ReactECharts
                style={{ height: "100%" }}
                option={chartOptions}
                onChartReady={null}
                opts={{ renderer: "canvas" }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default KenyaPage2;
