import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import img1 from "assets/images/CountryStories/KenyaPage3Img1.png";
import img2 from "assets/images/CountryStories/KenyaPage3Img2.png";
import img3 from "assets/images/CountryStories/KenyaPage3Img3.png";
import "./CountryPages.scss";

const KenyaPage3 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Kenya Page 3"
      id="kenP3"
      name="kenP3"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        Kenya&apos;s Digital Health Coverage​
      </header>
      <section className="story-content">
        <div className="main-row">
          <div
            className="col1"
            tabIndex={0}
            role="note"
            name="col1"
            aria-label="HIS SCALE UP AND DATA ERA"
          >
            <div className="left-div">
              <div className="image-frame">
                <img src={img1} name="img1" aria-hidden={true} />
              </div>
            </div>
          </div>
          <div
            className="col2"
            tabIndex={0}
            role="note"
            name="col2"
            aria-label="  PATIENT  CENTRIC  ERA  "
          >
            <div className="center-div">
              <div className="image-frame">
                <img src={img2} name="img2" aria-hidden={true} />
              </div>
              <div>
                <ul>
                  <li>
                    The NDW contained <font style={{ color: "blue" }}>61%</font>{" "}
                    of Kenya&apos;s PLHIV on ART.
                  </li>
                  <li>
                    <font style={{ color: "blue" }}>76%</font> of PLHIV ART
                    patients were seen at a site activeliy using the EMR.
                  </li>
                  <li>
                    <font style={{ color: "blue" }}>79%</font> of PLHIV ART
                    patients seen at EMR sites had records in NDW.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="col3"
            tabIndex={0}
            role="note"
            name="col3"
            aria-label="PUBLIC CENTRIC CARE ERA"
          >
            <div className="right-div">
              <div className="image-frame">
                <img src={img3} name="img3" aria-hidden={true} />
              </div>
              <div>
                <ul>
                  <li>
                    <font style={{ color: "blue" }}>97%</font> Reporting
                    concordance between the National Datawarehouse (NDW) and
                    DHIS2 for PLHIV current on ART.
                  </li>
                  <li>
                    The NDW contains <font style={{ color: "blue" }}>91%</font>{" "}
                    of Kenya&apos;s PLHIV on ART.
                  </li>
                  <li>
                    <font style={{ color: "blue" }}>93%</font> of PLHIV ART
                    Patients were seen at a site actively using the EMR
                  </li>
                  <li>
                    <font style={{ color: "blue" }}>99.8%</font> of PLHIV on ART
                    Patients seen at EMR Sites had records in NDW.​
                  </li>
                </ul>
              </div>
              <div>
                <p id="footnote">
                  ASAL* regions contribute less than{" "}
                  <font style={{ color: "blue" }}>5%</font> of PLHIV​
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default KenyaPage3;
