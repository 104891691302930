import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import togoMap from "assets/images/CountryStories/togoPg1.png";

import "./CountryPages.scss";

const TogoPage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="TogoPage1"
      id="tgPg1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        Togo Country Snapshot 2022​
      </header>
      <section className="story-content">
        <div className="pg1-row">
          <div tabIndex={0} className="text-blocks" name="section1" role="note">
            <h2>Context</h2>
            <ul className="left-list">
              <li>PEPFAR was launched in Togo 2019.</li>
              <li>
                The four regions supported, Grand Lome, Maritime, Plateaux and
                Centrale comprise 88% of the country’s total people living with
                HIV.​
              </li>
              <li>
                As of October 2022, PEPFAR supports 30 service delivery
                facilities and eight laboratories​.
              </li>
              <li>
                PEPFAR supports clinical services activities related to HIV
                diagnosis, antiretroviral treatment and viral load suppression​.
              </li>
              <li>
                In addition, PEPFAR invests above sites to strengthen Togo’s
                health systems and closely collaborate with the Ministry of
                Health and the Global Fund to Fight AIDS, Tuberculosis and
                Malaria to scale up PEPFAR’s best practices to non-supported
                regions and facilities​.
              </li>
            </ul>
          </div>

          <div>
            <figure role="group">
              <img
                src={togoMap}
                alt="Map of Togo"
                tabIndex={0}
                name="img1"
                role="img"
              />
            </figure>
          </div>
          <div tabIndex={0} className="text-blocks" name="section2" role="note">
            <h2>Background</h2>
            <ul className="right-list">
              <li>
                Total population: <strong>7.8 Million.​</strong>
              </li>
              <li>
                Adult HIV prevalence: ​ <strong>1.7%.</strong>
              </li>
              <li>
                Key Populations HIV prevalence: ​
                <ul>
                  <li>
                    Female Sex Worker:<strong> 7%.</strong>
                  </li>
                  <li>
                    Men Who Have Sex with Men: <strong>7.7%.</strong>
                  </li>
                  <li>
                    People Who Inject Drugs: <strong>3.4%.</strong>
                  </li>
                </ul>
              </li>
              <li>
                Estimated People Living with HIV: <strong>110,000.</strong>
              </li>
              <li>
                Estimated new infections: <strong>2,400.</strong>
              </li>
              <li>
                Antiretroviral Treatment coverage: <strong>82%.</strong>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TogoPage1;
