import { BehaviorSubject } from "rxjs";

const tableSort = new BehaviorSubject(0);
const fiscalYear = new BehaviorSubject("FY2021");
const subscriber = new BehaviorSubject(0);
const modalSwipe = new BehaviorSubject(false);

const messagingService = {
  sort: function (msg) {
    tableSort.next(msg);
  },

  updateYear: function (year) {
    fiscalYear.next(year);
  },

  send: function (msg) {
    subscriber.next(msg);
  },

  doSwipe: function (newVal) {
    modalSwipe.next(newVal);
  },
};

export { messagingService, tableSort, fiscalYear, subscriber, modalSwipe };
