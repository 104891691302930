import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import img1 from "assets/images/CountryStories/KenyaPage1.png";
import "./CountryPages.scss";

const KenyaPage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Kenya Page 1"
      id="kenP1"
      name="kenP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        Kenya&apos;s Digital Health Story
      </header>
      <section className="story-content">
        <div className="img-container">
          <div className="image-frame">
            <img
              src={img1}
              id="img1"
              name="img1"
              tabIndex={0}
              role="img"
              alt="Kenya page 1"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default KenyaPage1;
