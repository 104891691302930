import React from "react";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { chartColors } from "../charts";
import { getChartFontSize, getChartTitleSize } from "common";
import "components/CountryStories/pages/CountryPages.scss";

const MalawiPageTwo = () => {
  const theme = useSelector(selectCompliance).theme;
  const currentFontSize = getChartFontSize();
  const currentTitleSize = getChartTitleSize();

  const chartOptions1 = {
    aria: {
      show: false,
    },
    title: {
      text: "Number of EMRs by Type",
      left: "center",
      subtext:
        "POC = Point of Care (real-time entry)​     eMC = eMasterCard (retrospective entry)​",
      textStyle: {
        color: theme.color,
        fontSize: currentTitleSize,
      },
      subtextStyle: {
        color: theme.color,
        fontSize: currentFontSize,
        fontWeight: "bold",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "20%",
      show: true,
      borderWidth: 1,
      shadowColor: "rgba(0, 0, 0, 0.5)",
      shadowBlur: 10,
    },
    legend: {
      orient: "horizontal",
      bottom: 10,
      textStyle: { fontWeight: "bold", fontSize: currentFontSize },
    },
    tooltip: { formatter: "{b}<br/>{c} ({a})" },
    color: chartColors,
    label: {
      show: true,
      position: "top",
      fontSize: currentFontSize,
      fontWeight: "bold",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        color: theme.color,
        fontSize: currentFontSize,
        fontWeight: "bold",
      },
      data: [
        "2006",
        "2009",
        "2010",
        "2011",
        "2012",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
      ],
    },
    yAxis: {
      interval: 200,
      axisLabel: { color: theme.color, fontSize: currentFontSize },
    },
    series: [
      {
        name: "Other EMR's",
        type: "bar",
        label: {
          fontWeight: "bold",
          fontSize: currentFontSize,
        },
        data: [2, 6, 10, 12, 13, 13, 14, 14, 14, 14, 14, 14, 14, 14],
      },
      {
        name: "POC",
        type: "bar",
        label: {
          fontWeight: "bold",
          fontSize: currentFontSize,
        },
        data: [0, 2, 2, 2, 3, 7, 14, 17, 26, 194, 194, 197, 215, 219],
      },
      {
        name: "eMC",
        type: "bar",
        label: {
          fontWeight: "bold",
          fontSize: currentFontSize,
        },
        data: [0, 0, 0, 0, 0, 0, 0, 1, 17, 508, 513, 516, 534, 536],
      },
    ],
  };

  const chartOptions2 = {
    aria: {
      show: false,
    },
    title: {
      text: "Cumulative Number of EMRs",
      left: "center",
      textStyle: {
        color: theme.color,
        fontSize: currentTitleSize,
      },
      subtextStyle: {
        color: theme.color,
        fontSize: currentFontSize,
        fontWeight: "bold",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "20%",
      show: true,
      borderWidth: 1,
      shadowColor: "rgba(0, 0, 0, 0.5)",
      shadowBlur: 10,
    },
    legend: {
      orient: "horizontal",
      bottom: 10,
      textStyle: { fontWeight: "bold", fontSize: currentFontSize },
    },
    tooltip: { formatter: "{b}<br/>{c} Total EMR's" },
    color: chartColors,
    label: {
      show: true,
      position: "top",
      fontSize: currentFontSize,
      fontWeight: "bold",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        color: theme.color,
        fontSize: currentFontSize,
        fontWeight: "bold",
      },
      data: [
        "2006",
        "2009",
        "2010",
        "2011",
        "2012",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
      ],
    },
    yAxis: {
      interval: 100,
      min: 0,
      max: 900,
      axisLabel: { color: theme.color, fontSize: currentFontSize },
    },
    series: [
      {
        name: "Total EMR's",
        type: "bar",
        label: {
          fontWeight: "bold",
          fontSize: currentFontSize,
        },
        data: [2, 8, 12, 14, 16, 20, 28, 32, 57, 716, 721, 727, 763, 769],
      },
    ],
  };

  return (
    <div
      role="dialog"
      aria-label="Malawi Page 2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor }}
    >
      <header className="story-title" tabIndex={0} name="title" role="note">
        Malawi HIV EMR Landscape Started at 0 in 2006 to 769 Today
      </header>
      <section id="malawiP2">
        <div className="wrapper">
          <div
            className="chart-div"
            style={{ background: theme.bgGradient }}
            tabIndex={0}
            name="chart1"
            role="note"
            aria-label="This chart shows the number of electronic medical records by type over time since 2006."
          >
            <ReactECharts
              style={{ width: "100%", height: "100%" }}
              option={chartOptions1}
              onChartReady={null}
              opts={{ renderer: "canvas" }}
            />
          </div>
          <div
            className="chart-div"
            style={{ background: theme.bgGradient }}
            tabIndex={0}
            name="chart2"
            role="note"
            aria-label="This chart shows the cumulative number of electronic medical records since 2006."
          >
            <ReactECharts
              style={{ width: "100%", height: "100%" }}
              option={chartOptions2}
              onChartReady={null}
              opts={{ renderer: "canvas" }}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default MalawiPageTwo;
