// https://medium.com/carbondesign/color-palettes-and-accessibility-features-for-data-visualization-7869f4874fca
// https://v10.carbondesignsystem.com/data-visualization/color-palettes/#categorical-palettes

export const chartColors = [
  "#6929c4",
  "#1192e8",
  "#005d5d",
  "#9f1853",
  "#fa4d56",
  "#570408",
  "#198038",
  "#002d9c",
  "#ee538b",
  "#b28600",
  "#009d9a",
  "#012749",
  "#8a3800",
  "#a56eff",
];

export const chartColorsDark = [
  "#8a3ffc",
  "#33b1ff",
  "#007d79",
  "#ff7eb6",
  "#fa4d56",
  "#fff1f1",
  "#6fdc8c",
  "#4589ff",
  "#d12771",
  "#d2a106",
  "#08bdba",
  "#bae6ff",
  "#ba4e00",
  "#d4bbff",
];

export const mapColors = [
  "#6929c4",
  "#1192e8",
  "#005d5d",
  "#9f1853",
  "#fa4d56",
  "#570408",
  "#198038",
  "#002d9c",
  "#ee538b",
  "#b28600",
  "#009d9a",
  "#012749",
  "#8a3800",
  "#a56eff",
];
