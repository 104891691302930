import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";

import img1 from "assets/images/CountryStories/cam-2-img1.png";
import "./CountryPages.scss";

const CameroonPage2 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Cameroon Page 2"
      id="camP2"
      name="camP2"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        PEPFAR’s Contribution to Cameroon’s TX_CURR
      </header>
      <section className="story-content">
        <div className="img-container">
          <div className="image-frame">
            <img
              name="img1"
              id="img1"
              role="img"
              src={img1}
              tabIndex={0}
              alt="Timeline of Cameroon's national HIV program from 2011 to present day. PEPFAR now supports all 10 regions & 90% of TX_Curr."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CameroonPage2;
