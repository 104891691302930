import React from "react";
import PropTypes from "prop-types";
import "components/CountryStories/pages/CountryPages.scss";

function Divider(props) {
  return (
    <div id="divider-content" className="flipstory-container">
      <header className="divider-content">
        <div>
          <img src={props.headerImg} alt={props.content} aria-hidden={true} />
        </div>
        <label
          name="divider"
          className="divider"
          role="note"
          tabIndex={0}
          value={props.content}
        >
          {props.content}
        </label>
      </header>
    </div>
  );
}

Divider.propTypes = {
  headerImg: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  story: PropTypes.number.isRequired,
};

export default Divider;
